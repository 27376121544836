import React from 'react';
import {
  Button,
  Container,
  NumberInput,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  AiOutlineArrowRight,
  AiOutlineMail,
  AiOutlineUser,
} from 'react-icons/ai';

interface FormProps {
  assembleiaId: string;
  onSubmit: (data: { email: string; funcional: string | undefined }) => void;
}

export default function FormEmailAndFuncional({ onSubmit }: FormProps) {
  const emailInputLabel =
    process.env.REACT_APP_LABEL_EMAIL || 'Email (Ex: joaos@sanepar.com.br)';
  const funcionalInputLabel =
    process.env.REACT_APP_LABEL_RG_FUNCIONAL || 'Funcional (Ex: 876543)';
  const form = useForm({
    initialValues: {
      email: '',
      funcional: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'E-mail inválido'),
      funcional: (value) =>
        /^\d{5,8}$/.test(value) ? null : 'Funcional Inválido',
    },
  });

  return (
    <Container w="100%" p="lg">
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack>
          <TextInput
            label={emailInputLabel}
            icon={<AiOutlineMail />}
            required
            withAsterisk
            {...form.getInputProps('email')}
            size="lg"
            error={form.errors.email}
          />
          <NumberInput
            label={funcionalInputLabel}
            icon={<AiOutlineUser />}
            {...form.getInputProps('funcional')}
            size="lg"
            required
            hideControls
            error={form.errors.funcional}
          />
          <Button
            rightIcon={<AiOutlineArrowRight />}
            size="lg"
            color="green"
            type="submit"
          >
            VALIDAR E ACESSAR
          </Button>
        </Stack>
      </form>
    </Container>
  );
}
